<template>
  <div>
    <div>
      <button id="openBtn">
        Charger cloudinary
      </button>
      <hr>
      <div
        id="widget_container"
        style="height: 500px;"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    defaultPath: {
      type: String,
      default: 'site/product',
    },
  },
  mounted() {
    window.ml = window.cloudinary.createMediaLibrary(
      {
        cloud_name: 'kipli-cdn',
        api_key: '794467831499234',
        remove_header: true,
        max_files: '1',
        insert_caption: 'Insert',
        inline_container: '#widget_container',
        default_transformations: [[]],
        button_class: 'openBtn',
        button_caption: 'Charger cloudinary',
        folder: { path: 'site/product' },
      },
      {
        insertHandler(data) {
          data.assets.forEach(asset => {
            console.log('Inserted asset:', JSON.stringify(asset, null, 2))
          })
        },
      },
      document.getElementById('openBtn'),
    )
    console.log()
  },
}
</script>
