<template>
    <div>
        <cropper
        v-if="img"
        class="cropper"
        :src="img"
        :stencil-props="{
            aspectRatio: 2/3
        }"
        @change="change"
    />
    </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  components: {
    Cropper,
  },
  props: {
    img: {
      type: String,
      required: true,
    },
  },
  methods: {
    change({ coordinates, canvas }) {
      console.log(coordinates, canvas.toDataURL())
      canvas.toBlob(blob => {
        console.log(blob)
        this.$emit('change', { imageBuffer: blob, imageSrc: canvas.toDataURL() })
      }, 'image/jpeg')
    },
  },
}
</script>
<style scoped>
.cropper {
  height: 400px;
  width: 400px;
  background: #DDD;
}
</style>
