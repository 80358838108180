<template>
  <div>
    <b-modal
      v-model="openModal"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :hide-footer="true"
      title="Cloudinary"
    >
      <Media defaultPath />
    </b-modal>
    <b-button
      id="btnCloudinary"
      variant="info"
      @click="openModal = !openModal"
    >
      <span class="align-middle">Image Produit</span>
    </b-button>
  </div>
</template>
<script>

import {
  BButton, BModal,
} from 'bootstrap-vue'
import Media from '@components/cloudinary/Media.vue'

export default {
  components: { BButton, BModal, Media },
  props: {
    defaultPath: {
      type: String,
      default: '/site/product',
    },
  },
  data() {
    return {
      openModal: false,
    }
  },
}
</script>
