<template>
  <div>
    <p>Titre de la publication:</p>
    <b-form-input
      v-model="title"
      placeholder="Titre de la publication:"
      class="my-2"
    />
    <p>Image de la publication:</p>
    <div class="d-flex my-2">
      <div v-for="(imgSrc, keyI) of getImages()" :key="keyI">
        <img :src="imgSrc" class="rounded-lg mr-4 cursor-pointer" :class="{'border border-success border-3': keyI + 1 === selectIndex}" style="height: 200px" @click="selectIndex = keyI + 1" />
      </div>
    </div>
    <p>Resize:</p>
    <div class="d-flex">
      <Editor :img="getImages()[selectIndex - 1]" @change="e => setImage(e)"/>
        <div class="d-flex ml-2">
          <p class="mr-2">Apercu:</p>
          <img :src="imagePin" style="height: 400px"/>
        </div>
    </div>
    <p>Date la publication:</p>
    <flat-pickr
      v-model="date"
      class="form-control flat-picker my-2"
      placeholder="YYYY-MM-DD"
    />
    <BButton :disabled="loading" @click="create">Publier</BButton>
  </div>
</template>

<script>

import {
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import Editor from '@/components/image/Editor.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    BFormInput,
    flatPickr,
    Editor,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectIndex: 1,
      loading: false,
      title: `${this.product.name || this.product.title} - Kipli`,
      date: null,
      imagePin: null,
      imageBuffer: null,
    }
  },
  methods: {
    getImages() {
      const images = [1, 2, 3, 4, 5].map(i => `https://res.cloudinary.com/kipli-cdn/image/upload/site/product/${this.product.sku.toLowerCase()}/product-page/${i}.jpg`)
      return images
    },
    setImage(e) {
      this.imagePin = e.imageSrc
      this.imageBuffer = e.imageBuffer
    },
    async create() {
      try {
        if (['', null].includes(this.title) || ['', null].includes(this.date)) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Il manque le titre ou la date',
              icon: 'EditIcon',
              variant: 'error',
            },
          })
        }
        this.loading = true
        const form = new FormData()
        form.append('file', this.imageBuffer, `${this.$moment().unix()}.jpg`)
        const { url } = await this.$http.post('/admin/upload-image', form)
        const { success } = await this.$http.post('/admin/pinterest', {
          id: this.product.id, title: this.title, date: this.$moment(this.date), image: url,
        })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Bien ajouté',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        if (success) {
          this.$emit('close', true)
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
